import React from "react";
import { graphql } from "gatsby";
import { Container } from "reactstrap";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import Layout from "../../../layouts/index";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/resources/banner-resources2.jpg";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Images
import BannerDedicated from "../../../images/services/resources/banner-dedicated.jpg";
import BannerUxui from "../../../images/services/resources/banner-ux-ui.jpg";
import BannerResearch from "../../../images/services/resources/banner-research.jpg";
import BannerTest from "../../../images/services/resources/banner-test.png";
import BannerCto from "../../../images/services/resources/banner-cto.jpg";

// Testimonial
import TabcmpSet from "../../../components/tab/TabcmpSet";
import ClientTestimonial5 from "../../../components/ClientTestimonialBenOxygen";
import BenTaylor from "../../../images/Clients/BenTaylor.jpg";

// Casestudy
import downloadFile from "../../../documents/Case-Study-Gigaclear-Offshore-Staff-Augmentation.pdf";
import caseStudyImg from "../../../images/Enterprise-Integration.jpg";

// Contact
import GetInTouchbtn from "../../../components/GetInTouchbtn";
import OurProcess from "../../../components/services/OurProcess";
import BookConsultation from "../../../components/BookConsultation";
import ConsultationBanner from "../../../images/consultation.jpg";
import SvgIconsSet from "../../../components/SvgIconsSet";

const Resources = () => {
  const tabData = [
    {
      label: "Ben Taylor",
      text: "Oxygen Broking Services",
      image: BenTaylor,
      content: <ClientTestimonial5 />,
    },
  ];

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile]);

    const formData = { ...values };
    formData.Message = "CASE STUDY: HOW GIGACLEAR REDUCED OPERATIONAL COSTS WITH STAFF AUGMENTATION";
    formData.FormType = "CaseStudy";
    formData.Subject = "CASE STUDY: RESOURCES";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="page-services resource-service">
      <Seo
        keyword="IT Managed Teams"
        title="Certified Professionals"
        description="Extend your team with our team. We offer dedicated managed teams, R&D as a service, CTO as a service and Business Analyst services. Scale easily and quickly with iTelaSoft"
      />
      <IntroBanner
        title="Certified Professionals"
        subtitle="Extend your team with our team"
        image={Banner}
      />
      <div className="container resources-pg pb-6 section-2">
        <div className="row pt-6 mb-3">
          <div className="col-12">
            <h2 className="title-1">Our Services</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerDedicated}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">
              Scale IT resources up or down as required
            </h2>
            <p>
              Don’t let resource issues get in the way of progress. We offer
              specialist expertise to meet your needs so you can continue with
              your innovation or project plans. Our resource models include full
              managed teams or embedded staff augmentation.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerUxui}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Accelerate innovation & deployment</h2>
            <p>
              We offer R&D as a service, UX/UI as a service and Business Analyst
              services so you can quickly engage our experts for your next
              project and accelerate your time to market. Our experienced
              resources will conduct innovative research and development
              activities, facilitating the creation of new products and
              technologies without the reliance on in-house capabilities or
              capacity.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerResearch}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">
              Access Salesforce development expertise on demand
            </h2>
            <p>
              When it comes to complex Salesforce development, our expert
              development team (led by a
              {" "}
              <a
                className="resource-pg-link"
                href="https://www.itelasoft.com.au/blog/New-Addition-to-Salesforce-MVP-Hall-of-Fame-Sets-Global-Milestone/"
                target="_blank"
                rel="noreferrer"
              >
                Salesforce MVP Hall of Fame recipient
                {" "}
              </a>
              ) are leaders in designing and developing custom applications,
              features or integrations within the Salesforce platform. No matter
              how simple or complex your needs, our team can help.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5 order-md-2">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerTest}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">
              Ensure the quality of your products & services
            </h2>
            <p>
              As an
              {" "}
              <a
                className="resource-pg-link"
                href="https://www.itelasoft.com.au/blog/iTelaSoft-levels-up-software-quality-with-ISTQB-Platinum-Partnership/"
                target="_blank"
                rel="noreferrer"
              >
                ISTQB Platinum Partner
              </a>
              , we know how vital quality engineering & validation is to
              customer satisfaction, cost savings, regulatory compliance and
              competitive advantage.
              <br />
              Whether you run an existing software platform, are planning to
              modernise one, or building a tech product from the scratch, our
              expert team can help you with all your software testing needs.
            </p>
          </div>
        </div>
        <div className="row py-6">
          <div className="col-md-6 pr-md-5">
            <img
              alt="product innovation"
              className="img-fluid"
              src={BannerCto}
            />
          </div>
          <div className="col-md-6 pt-3 pt-md-0 d-flex flex-column justify-content-center">
            <h2 className="title-2">Engage a CTO as a Service</h2>
            <p>
              We offer customised CTO services so you can access the strategic
              leadership and expert guidance on IT services or technological
              innovation when you need it. Typically offered on a flexible or
              part time basis, we can customise a service model to suit you.
            </p>
          </div>
        </div>
        <div className="getting-touch">
          <GetInTouchbtn textdce="Free consultation" />
        </div>
      </div>

      <Container>
        <OurProcess />
      </Container>

      <section>
        <div className="wrap-case-study mt-3">
          <Container>
            <div className="row py-6">
              <div className="col-12">
                <div className="career">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-6 flex-column">
                        <div>
                          <h5 className="title-5 mb-0">Case Study</h5>
                          <h3 className="title-3 mt-0">
                            How Gigaclear reduced operational costs with staff
                            augmentation
                          </h3>
                          <p>
                            Gigaclear, an award-winning broadband provider in
                            the UK, achieved unparalleled results with a custom
                            staff augmentation solution from iTelaSoft.
                          </p>
                        </div>
                        <div className="mt-2 mb-3">
                          <p><b>Download the Case Study Now!</b></p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column justify-content-md-between mb-1"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="col-lg col-md pr-1 col-sm-7 col-12 p-0">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mt-1 mt-sm-0 mb-2 ${
                                        subscriptions.hasValidationErrors
                                        || subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download Case Study
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />

                          <p className="small-text">
                            By downloading this case study, you agree to receive
                            emails from iTelaSoft about products, services, and
                            updates. You can unsubscribe at any time.
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <img
                          alt="product innovation"
                          className="img-fluid"
                          src={caseStudyImg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className="client-testimonial-section pt-5">
        <Container>
          <div className="tab-header-resources">
            <h3 className="title-1">What our client says</h3>
          </div>
          <TabcmpSet tabData={tabData} tabNum={1} />
        </Container>
      </section>

      <Container className="my-6">
        <h2 className="title-1 mb-3">Book a free consultation</h2>
        <BookConsultation image={ConsultationBanner} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "managed-services-1024x528.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Resources;
